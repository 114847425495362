<template>
  <div id="app" :class="bgClass">
    <div class="mode-selector">
      <button :class="{ active: currentMode === 'train' }" @click="setMode('train')">訓練模式</button>
      <button :class="{ active: currentMode === 'rank' }" @click="setMode('rank')">排名賽模式</button>
      <button :class="{ active: currentMode === 'match' }" @click="setMode('match')">對抗賽模式</button>
    </div>
    <div class="toolbar">
      <button @click="startTimer">開始</button>
      <button @click="stopTimer">停止</button>
      準備秒數 <input v-model="prepareSeconds" type="number" placeholder="準備秒數" style="width:100px" />
      計時秒數 <input v-model="workMinutes" type="number" placeholder="計時秒數" style="width:100px" />
      休息秒數 <input v-model="restMinutes" type="number" placeholder="休息秒數" style="width:100px" />
      <button @click="enableSound" v-if="!isEnableSound">有聲音</button>
      <button @click="disableSound" v-if="isEnableSound">無聲</button>
      <button @click="saveSettingsNow">儲存設定</button>
    </div>
    <div class="display">
      {{ displayTime }}
    </div>
  </div>
</template>

<script lang="ts">
import { ref, computed, onMounted, nextTick } from 'vue'

export default {
  setup() {
    const modes = ['train', 'rank', 'match']
    const currentMode = ref('train')

    const prepareSeconds = ref(10)
    const workMinutes = ref(180)
    const restMinutes = ref(180)
    const secondsLeft = ref(0)
    const isWorking = ref(false)
    const isPreparing = ref(true)
    const isStoppedDueToRestZero = ref(false) // 新增變數來追蹤因休息秒數為0而停止的狀態
    const timer = ref(0)
    const isEnableSound = ref(true)

    // 預加載音頻
    const Beep1_Work = new Audio("buzzer1.wav")
    const Beep2_Rest = new Audio("buzzer1.wav")

    // 確保音頻是加載完成狀態
    Beep1_Work.preload = 'auto'
    Beep2_Rest.preload = 'auto'

    const displayTime = computed(() => secondsLeft.value)

    const bgClass = computed(() => {
      if (isStoppedDueToRestZero.value) return 'rest'
      if (isPreparing.value && prepareSeconds.value > 0) return 'prepare'
      return isWorking.value ? 'work' : 'rest'
    })

    const loadSettings = (mode: string) => {
      prepareSeconds.value = parseInt(localStorage.getItem(`${mode}_prepareSeconds`) || '10')
      workMinutes.value = parseInt(localStorage.getItem(`${mode}_workMinutes`) || '180')
      restMinutes.value = parseInt(localStorage.getItem(`${mode}_restMinutes`) || '180')
    }

    const saveSettings = (mode: string) => {
      localStorage.setItem(`${mode}_prepareSeconds`, prepareSeconds.value + "")
      localStorage.setItem(`${mode}_workMinutes`, workMinutes.value + "")
      localStorage.setItem(`${mode}_restMinutes`, restMinutes.value + "")
    }

    const saveSettingsNow = () => {
      saveSettings(currentMode.value)
    }

    const workTimerStart = () => {
      console.log('Work timer started')
      PlaySound(Beep1_Work)
    }
    const workTimerEnd = () => {
      console.log('Work timer ended')
      PlaySound(Beep1_Work)
      if (restMinutes.value === 0) {
        isStoppedDueToRestZero.value = true // 將標誌設置為 true
        stopTimer() // 停止運作
      } else {
        isWorking.value = false
        nextTick(() => {
          secondsLeft.value = restMinutes.value
        })
      }
    }
    const restTimerStart = () => {
      console.log('Rest timer started')
    }
    const restTimerEnd = () => {
      console.log('Rest timer ended')
      isWorking.value = true
      nextTick(() => {
        secondsLeft.value = workMinutes.value
      })
    }

    const timerRule = () => {
      if (secondsLeft.value > 0) {
        secondsLeft.value--
      } else {
        if (isPreparing.value) {
          if (prepareSeconds.value === 0) {
            // 若準備時間為 0，直接進入工作階段
            isPreparing.value = false
            isWorking.value = true
            PlaySound(Beep1_Work)
            nextTick(() => {
              secondsLeft.value = workMinutes.value
            })
          } else {
            // 正常準備階段
            isPreparing.value = false
            isWorking.value = true
            PlaySound(Beep1_Work)
            nextTick(() => {
              secondsLeft.value = workMinutes.value
            })
          }
        } else {
          if (isWorking.value) {
            workTimerEnd()
          } else {
            restTimerEnd()
          }
        }
      }
    }

    const PlaySound = (audio: HTMLAudioElement) => {
      if (isEnableSound.value) {
        audio.currentTime = 0
        audio.play().catch(err => console.log('Audio playback error:', err))
      }
    }

    // 播放兩次音效（使用 Promise）
    const PlaySound2 = async () => {
      if (isEnableSound.value) {
        try {
          await PlaySoundOnce(Beep2_Rest)
          setTimeout(() => PlaySoundOnce(Beep2_Rest), 500) // 延遲 0.5 秒播放第二次
        } catch (err) {
          console.log('Audio playback error:', err)
        }
      }
    }

    // 簡化音頻播放控制
    const PlaySoundOnce = (audio: HTMLAudioElement) => {
      return new Promise<void>((resolve, reject) => {
        audio.currentTime = 0
        audio.play().then(() => {
          audio.onended = () => resolve()
        }).catch(err => reject(err))
      })
    }

    const startTimer = () => {
      isStoppedDueToRestZero.value = false // 開始時重置標誌
      nextTick(() => {
        if (prepareSeconds.value > 0) {
          isPreparing.value = true
          secondsLeft.value = prepareSeconds.value
          PlaySound(Beep1_Work) // 準備階段開始前播放 Beep1_Work
        } else {
          isPreparing.value = false
          isWorking.value = true
          secondsLeft.value = workMinutes.value
          PlaySound(Beep1_Work) // 直接進入工作階段播放 Beep1_Work
        }

        timer.value = setInterval(timerRule, 1000)
      })
    }

    const stopTimer = () => {
      if (timer.value !== 0) {
        clearInterval(timer.value)
        timer.value = 0
      }
      nextTick(() => {
        secondsLeft.value = 0
      })
      PlaySound2() // 停止時播放 Beep2_Rest
    }

    const enableSound = () => {
      isEnableSound.value = true
    }

    const disableSound = () => {
      isEnableSound.value = false
    }

    const setMode = (mode: string) => {
      nextTick(() => {
        if (currentMode.value !== mode) {
          saveSettings(currentMode.value)
          currentMode.value = mode
          loadSettings(mode)
        }
      })
    }

    onMounted(() => {
      loadSettings(currentMode.value)
      if (localStorage.getItem('isEnableSound')) {
        isEnableSound.value = localStorage.getItem('isEnableSound') === 'true'
      }
    })

    return {
      prepareSeconds,
      workMinutes,
      restMinutes,
      startTimer,
      stopTimer,
      displayTime,
      isWorking,
      isPreparing,
      enableSound,
      disableSound,
      isEnableSound,
      bgClass,
      currentMode,
      setMode,
      saveSettingsNow,
      isStoppedDueToRestZero // 添加返回值
    }
  }
}
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.mode-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.mode-selector button {
  margin: 0 5px;
  padding: 10px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
}

.mode-selector button.active {
  background-color: #007bff;
  color: white;
}

.toolbar {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.display {
  flex: 1 0 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(90vw / 2.3);
}

.work {
  background-color: green;
  color: black !important;
}

.rest {
  background-color: red;
  color: white !important;
}

.prepare {
  background-color: red;
  color: white !important;
}
</style>
