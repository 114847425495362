import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "mode-selector" }
const _hoisted_2 = { class: "toolbar" }
const _hoisted_3 = { class: "display" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "app",
    class: _normalizeClass($setup.bgClass)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: _normalizeClass({ active: $setup.currentMode === 'train' }),
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.setMode('train')))
      }, "訓練模式", 2),
      _createElementVNode("button", {
        class: _normalizeClass({ active: $setup.currentMode === 'rank' }),
        onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.setMode('rank')))
      }, "排名賽模式", 2),
      _createElementVNode("button", {
        class: _normalizeClass({ active: $setup.currentMode === 'match' }),
        onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.setMode('match')))
      }, "對抗賽模式", 2)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($setup.startTimer && $setup.startTimer(...args)))
      }, "開始"),
      _createElementVNode("button", {
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => ($setup.stopTimer && $setup.stopTimer(...args)))
      }, "停止"),
      _createTextVNode(" 準備秒數 "),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.prepareSeconds) = $event)),
        type: "number",
        placeholder: "準備秒數",
        style: {"width":"100px"}
      }, null, 512), [
        [_vModelText, $setup.prepareSeconds]
      ]),
      _createTextVNode(" 計時秒數 "),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.workMinutes) = $event)),
        type: "number",
        placeholder: "計時秒數",
        style: {"width":"100px"}
      }, null, 512), [
        [_vModelText, $setup.workMinutes]
      ]),
      _createTextVNode(" 休息秒數 "),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.restMinutes) = $event)),
        type: "number",
        placeholder: "休息秒數",
        style: {"width":"100px"}
      }, null, 512), [
        [_vModelText, $setup.restMinutes]
      ]),
      (!$setup.isEnableSound)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => ($setup.enableSound && $setup.enableSound(...args)))
          }, "有聲音"))
        : _createCommentVNode("", true),
      ($setup.isEnableSound)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => ($setup.disableSound && $setup.disableSound(...args)))
          }, "無聲"))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => ($setup.saveSettingsNow && $setup.saveSettingsNow(...args)))
      }, "儲存設定")
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString($setup.displayTime), 1)
  ], 2))
}